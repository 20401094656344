import { useState, useEffect, useMemo, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MaterialReactTable } from 'material-react-table';
import { Button, Avatar, CircularProgress, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Alert, Snackbar } from '@mui/material';
import { HiOutlinePencilAlt, } from "react-icons/hi";
import { useOrder } from '../../contexts/OrderContext';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';


// Styles for the modal

const OrderTable = ({ shopId }) => {

  const { orders, shopOrders, updateOrderStatus, loading, loadingFetch, fetchOrders } = useOrder();
  const [title, setTitle] = useState('Commandes');
  // const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const [map, setMap] = useState(null);

  // useEffect(() => {
  //   if (selectedOrder?.deliveryLocation && !map) {
  //     const mapInstance = new mapboxgl.Map({
  //       container: 'map',
  //       style: 'mapbox://styles/mapbox/streets-v12',
  //       center: [selectedOrder.deliveryLocation.lng, selectedOrder.deliveryLocation.lat],
  //       zoom: 15
  //     });
  
  //     // Add delivery location marker
  //     new mapboxgl.Marker()
  //       .setLngLat([selectedOrder.deliveryLocation.lng, selectedOrder.deliveryLocation.lat])
  //       .setPopup(new mapboxgl.Popup().setHTML("<h3>Point de livraison</h3>"))
  //       .addTo(mapInstance);
  
  //     // Add nearest POI marker if available
  //     if (nearestPOI) {
  //       new mapboxgl.Marker({ color: '#FF0000' })
  //         .setLngLat([nearestPOI.lng, nearestPOI.lat])
  //         .setPopup(new mapboxgl.Popup().setHTML(`
  //           <h3>Point de repère</h3>
  //           <p>${nearestPOI.label}</p>
  //           <p>Distance: ${nearestPOI.distance}m</p>
  //         `))
  //         .addTo(mapInstance);
  //     }
  
  //     setMap(mapInstance);
  //   }
  
  //   return () => {
  //     if (map) map.remove();
  //   };
  // }, [selectedOrder, nearestPOI]);

  const customIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // if(loadingFetch){
  //   fetchOrders();
  // }

  useEffect(() => {
    if (shopId) {
      fetchOrders();
    }
  }, [shopId, fetchOrders]);

  console.log("Orders table", orders);
  // handleFetchOredresByShop();

  // console.log(ordersByShop);

  const filtredOrders = useMemo(() => {
    if (!orders || orders.length === 0) return [];

    // First find all orders that have shopOrders containing our shopId
    const relevantOrders = orders.filter(order =>
      order?.shopOrders?.some(shopOrder => shopOrder.shopId === shopId)
    );

    // Map these orders to the format we need while preserving the original order ID
    return relevantOrders.map(order => {
      const shopOrder = order.shopOrders.find(so => so.shopId === shopId);
      return {
        _id: order._id, // Keep the main order ID
        customer: order.customer,
        items: shopOrder.items,
        total: shopOrder.total,
        status: order.status,
        createdAt: order.createdAt,
        deliveryLocation: order.deliveryLocation,
        shopId: shopOrder.shopId
      };
    });
  }, [orders, shopId]);





  console.log("filtredOrders", filtredOrders);
  console.log("selected order", selectedOrder);
  const handleOpen = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  // Table columns definition
  const columns = useMemo(() => [
    {
      accessorKey: 'customer.name',
      header: 'Nom du client',
      size: 120,
    },
    {
      accessorKey: 'customer.phone',
      header: 'Numéro du client',
      size: 120,
    },
    {
      accessorKey: 'items', // Use 'items' as the key for the articles
      header: 'Articles',
      size: 120,
      Cell: ({ cell }) => (
        <>
          {cell.getValue().map((item) => (
            <p key={item?._id}>
              {item?.itemId?.name} x {item?.quantity}
            </p>
          ))}
        </>
      ),
    },
    {
      accessorKey: 'total',
      header: 'Prix total',
      size: 100,
      Cell: ({ cell }) => `${cell.getValue()} FCFA`,
    },
    {
      accessorKey: 'status',
      header: 'Statut',
      size: 100,
      Cell: ({ cell }) => renderStatus(cell.getValue()),
    },
    {
      accessorKey: 'createdAt',
      header: 'Date et heure',
      size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue()),
    },
    {
      header: 'Actions',
      size: 100,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', columnGap: 3 }}>
          <Avatar
            sx={{ width: '30px', height: '30px', cursor: 'pointer' }}
            onClick={() => handleOpen(row.original)}
          >
            <HiOutlinePencilAlt />
          </Avatar>
        </Box>
      ),
    },
  ], []);

  const handleUpdateStatus = () => {
    if (selectedOrder) {
      updateOrderStatus(selectedOrder?._id, selectedOrder?.status);
      setOpen(false);
      setSelectedOrder(null);
    }
  }
  console.log("selectedOrder", selectedOrder);
  // Articles are now rendered as part of the items inside each row

  const articles = filtredOrders?.flatMap(order => order?.items);
  console.log("articles", articles);


  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };
  const renderStatus = (status) => {
    let color = '';
    let label = '';

    switch (status) {
      case 'new':
        color = 'info.main';
        label = 'Nouvelle commande';
        break;
      case 'pending':
        color = 'text.secondary';
        label = 'Commande en attente';
        break;
      case 'processing':
        color = 'warning.main';
        label = 'Commande en cours de traitement';
        break;
      case 'completed':
        color = 'secondary.main';
        label = 'Commande envoyé';
        break;
      case 'canceled':
        color = 'error.main';
        label = 'Commande Annuler';
        break;
      default:
        color = 'text.secondary';
        label = 'Inconnu';
    }

    return (
      <Box
        sx={{
          backgroundColor: color,
          color: '#fff',
          px: 2,
          py: 0.5,
          borderRadius: '4px',
          textAlign: 'center',
        }}
      >
        {label}
      </Box>
    );
  };
  // console.log(ordersByShop)
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return Math.round(R * c); // Distance in meters
  }


  async function findNearestPOI(lat, lng) {
    try {
      const radius = 300; // Rayon de recherche en mètres
      const searchQuery = encodeURIComponent('restaurant OR pharmacie OR supermarché OR école OR banque');

      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?` +
        `q=${searchQuery}` +
        `&format=json` +
        `&lat=${lat}` +
        `&lon=${lng}` +
        `&limit=5` +
        `&countrycodes=sn,ci,cm,ml,bf` + // Codes pays africains
        `&bounded=1` +
        `&viewbox=${lng - 0.01},${lat - 0.01},${lng + 0.01},${lat + 0.01}`
      );

      const places = await response.json();

      // Filtrer les lieux dans le rayon spécifié
      const nearbyPlaces = places.filter(place => {
        const distance = calculateDistance(
          lat, lng,
          parseFloat(place.lat),
          parseFloat(place.lon)
        );
        return distance <= radius;
      });

      if (nearbyPlaces.length > 0) {
        const nearest = nearbyPlaces[0];
        const distance = calculateDistance(
          lat, lng,
          parseFloat(nearest.lat),
          parseFloat(nearest.lon)
        );

        return {
          label: nearest.display_name,
          lat: parseFloat(nearest.lat),
          lng: parseFloat(nearest.lon),
          distance
        };
      }
      return null;
    } catch (error) {
      console.error('Erreur lors de la recherche:', error);
      return null;
    }
  }





  const [nearestPOI, setNearestPOI] = useState(null);

  useEffect(() => {
    if (selectedOrder?.deliveryLocation) {
      const { lat, lng } = selectedOrder?.deliveryLocation;
      findNearestPOI(lat, lng).then(poi => setNearestPOI(poi));
    } else {
      setNearestPOI(null);
    }
  }, [selectedOrder]);


  return (
    <div>
      {
        filtredOrders?.length < 1 ? (
          <p className="text-3xl font-bold text-orange-400 text-center py-14">Aucune commandes dans cette catégorie pour l'instant</p>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">Liste des {title}</h2>
            <Box>
              <MaterialReactTable
                columns={columns.map((column) => ({
                  ...column,

                  muiTableBodyCellProps: {
                    sx: {
                      backgroundColor: 'rgba(0, 128, 128, 0.1)', // Appliquer une couleur de fond aux cellules du corps
                    },
                  },
                  muiTableHeadCellProps: {
                    sx: {
                      backgroundColor: 'teal', // Appliquer une couleur de fond aux en-têtes de colonnes
                      color: 'white', // Changer la couleur du texte pour une meilleure lisibilité
                    },
                  },
                }))}
                data={filtredOrders} // Filtrer les articles par url
                enableRowSelection={false}
                enablePagination
                muiTableProps={{
                  sx: {
                    tableLayout: 'fixed',
                  },
                }}
              />
            </Box>
          </>

        )
      }

      {/* Modal pour afficher les détails de la commande et mettre à jour le statut */}
      {selectedOrder && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          className='bg-teal-300'
          sx={{
            backgroundColor: 'rgba(0, 128, 128, 0.1)', // Appliquer une couleur de fond aux cellules du corps
          }}

        >
          <DialogTitle
            sx={{
              backgroundColor: 'rgba(0, 128, 128,0.8)', // Appliquer une couleur de fond aux cellules du corps
            }}
            variant='h4'>Information de la commande</DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: 'rgba(0, 128, 128, 0.1)', // Appliquer une couleur de fond aux cellules du corps
            }}>
            <Box
              sx={{ display: "flex", justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem' }}
            >

              <Box className='shadow-sm p-2 rounded ' sx={{ width: { xs: '100%', md: '30%' } }}>
                <p className='text-lg text-teal-700 mb-2'>Détails du client</p>
                <p className='mb-2'><b className=' text-emerald-600'>Nom du client</b> : {selectedOrder?.customer?.name}</p>
                <p className='mb-2'><b className=' text-emerald-600'>Numéro du client</b>: {selectedOrder?.customer?.phone}</p>
                <p className='mb-2'><b className=' text-emerald-600'>Email du client</b>: {selectedOrder?.customer?.email}</p>
              </Box>
              <Box className='shadow-sm p-2 rounded ' sx={{ width: { xs: '100%', md: '30%' } }}>
                <p className='text-lg text-teal-700 mb-2'>Détails de la commande</p>
                <p className='mb-2'><b className=' text-emerald-600'>ID</b>: {selectedOrder?._id}</p>
                <p className='mb-2'><b className=' text-emerald-600'>Date et heure de la commande</b> : {formatDate(selectedOrder?.createdAt)}</p>
                <p className='mb-2'><b className=' text-emerald-600'>Articles commander</b> :</p>
                {selectedOrder?.items.map((item) => (
                  <p className='' key={item?.itemId?._id}>
                    {item?.itemId?.name} - {item?.itemId?.price} FCFA x {item?.quantity}
                  </p>
                ))}
              </Box>
              <Box className='shadow-sm p-2 rounded ' sx={{ width: { xs: '100%', md: '36%' } }}>
                <p className='text-lg text-teal-700 mb-2'>Détails de la livraison</p>
                {selectedOrder?.deliveryLocation && (
                  <>
                    <div style={{ height: '200px', width: '100%', marginTop: '10px' }}>
                      <div style={{ height: '200px', width: '100%', marginTop: '10px' }}>
                      <div id="map" style={{ height: '300px', width: '100%', borderRadius: '8px' }} />

                      </div>

                      {nearestPOI && (
                        <div className="mt-2 p-2 bg-teal-50 rounded">
                          <p className="font-semibold text-teal-700">Point de repère proche:</p>
                          <p>{nearestPOI.label}</p>
                          <p className="text-teal-600">Distance: {nearestPOI.distance} mètres</p>
                        </div>
                      )}
                    </div>

                  </>
                )}

              </Box>
            </Box>


            <p className='text-xl text-red-600 my-3'>Coût total: {selectedOrder?.total} FCFA</p>
            <TextField
              select
              label="Statut de la commande"
              value={selectedOrder?.status}
              onChange={(e) => setSelectedOrder({ ...selectedOrder, status: e.target.value })}
              sx={{ width: '200px' }}
              margin="normal"
            >
              <MenuItem value="pending">En attente</MenuItem>
              <MenuItem value="processing">Confirmer</MenuItem>
              <MenuItem value="completed">Envoyé</MenuItem>
              <MenuItem value="canceled">Annulée</MenuItem>
            </TextField>
            <Button variant="contained" color="primary" className="mt-4" onClick={() => handleUpdateStatus()} >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Mettre à jour le statut'}
            </Button>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: 'rgba(0, 128, 128,0.8)', // Appliquer une couleur de fond aux cellules du corps
            }}
          >
            <Button onClick={handleClose} color="secondary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>


  );
};

export default OrderTable;
